<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.headerStyle" class="component-container">
        <component :is="cmsBlock.htmlTag" class="header" v-html="localize(cmsBlock.text)"></component>
    </section>
</template>

<script>
    export default {
        name: 'HeaderBlock',

        props: [
            'cmsBlock'
        ]
    }
</script>

<style lang="scss" scoped>
.component-container {

    .header {
        text-align: center;
        word-break: break-word;
        hyphens: auto;

        max-width: $container-width;
        margin: 0 auto;

        @include breakpoint('max-width') {
            padding: 0 getSpacing('padding-default-horizontal', 'desktop');
        }
        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }
        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>
